<template lang="pug">
article.route.bio
    section.hero
        .hero-container
            .hero-image-container.transition-start
                picture
                    source(media="(min-width: 1440px)" srcset="../assets/images/bio/bgLarge.webp")
                    source(media="(min-width: 768px)" srcset="../assets/images/bio/bgMedium.webp")
                    img.hero-image(src="../assets/images/bio/bgSmall.webp" alt="")
            .section-container
                .hero-text.transition-start.from-left
                    h1.hero-headline {{ $t('bio.hero.headline') }}
                    p.hero-lead {{ $t('bio.hero.lead') }}
    .content.bright-bg
        section.section.section
            .section-container.section-container--grid
                //- Studies
                .section-text-container.transition-start.from-left
                    h2.h2 {{ $t('bio.content1.title') }}
                    p.from-left(style="transition-delay:.2s") {{ $t('bio.content1.p1') }}
                    p.from-left(style="transition-delay:.4s") {{ $t('bio.content1.p2') }}
                    p.from-left(style="transition-delay:.6s") {{ $t('bio.content1.p3') }}
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/bio/studies.webp" alt="").studies-pic
                //- Academic years
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/bio/academic.webp" alt="").academic-pic
                .section-text-container.transition-start.from-right
                    h2.h2 {{ $t('bio.content2.title') }}
                    p.from-right(style="transition-delay:.2s") {{ $t('bio.content2.p1') }}
                    p.from-right(style="transition-delay:.4s") {{ $t('bio.content2.p2') }}
                    p.from-right(style="transition-delay:.6s") {{ $t('bio.content2.p3') }}
                    p.from-right(style="transition-delay:.8s") {{ $t('bio.content2.p4') }}
                //- Recent years
                .section-text-container.transition-start.from-left
                    h2.h2 {{ $t('bio.content3.title') }}
                    p.from-left(style="transition-delay:.2s") {{ $t('bio.content3.p1') }}
                    p.from-left(style="transition-delay:.4s") {{ $t('bio.content3.p2') }}
                    p.from-left(style="transition-delay:.6s") {{ $t('bio.content3.p3') }}
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/bio/recent.webp" alt="").recent-pic
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/bio/education.webp" alt="").education-pic
                //- Education
                .section-text-container.transition-start.from-right
                    h2.h2 {{ $t('bio.content4.title') }}
                    p.from-right(style="transition-delay:.2s") {{ $t('bio.content4.p1') }}
                    p.from-right(style="transition-delay:.4s") {{ $t('bio.content4.p2') }}
                    p.from-right(style="transition-delay:.6s") {{ $t('bio.content4.p3') }}
                //- Music
                .section-text-container.transition-start.from-left
                    h2.h2 {{ $t('bio.content5.title') }}
                    p.from-left(style="transition-delay:.2s") {{ $t('bio.content5.p1') }}
                    p.from-left(style="transition-delay:.4s") {{ $t('bio.content5.p2') }}
                    p.from-left(style="transition-delay:.6s") {{ $t('bio.content5.p3') }}
                .section-image-container.transition-start
                    img.section-image(src="../assets/images/bio/music.webp" alt="").music-pic
        section.section.lightbrown-bg
            .section-container.transition-start
                h2.h2.tac {{$t('bio.partners.title')}}
                .logos
                    img.logo.from-place(v-for="index in 9" :key="index" :src="require(`../assets/images/bio/p${index}.webp`)" alt="" :style="`transition-delay: ${index / 10 * 2}s`")


</template>

<script>
import intersectionObserver from '@/js/mixins/intersectionObserver.js'

export default {
    name: 'Bio',
    mixins: [intersectionObserver],
    mounted() {
        window.scrollTo(0, 0)
      }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

section .section-container {
    text-align: left;
}

.section-image-container {
    box-shadow: $button-shadow;
}

.section-image-container img {
    height: 163%;
}

.studies-pic {
    top: 24%;
}

.academic-pic {
    top: 52%;
}

.recent-pic {
    top: 22%;
}

.education-pic {
    top: 70%;
}

.music-pic {
    top: 49%;
}
</style>
